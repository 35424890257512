import React from 'react';
import Layout from 'components/layout';
import SeoComponent from 'components/seo';
import {Breadcrumb, Card, Button} from "components/ui";
import { Link } from 'gatsby';
import interactiveStatisticsCharts from "data/interactive-statistics-series-data.js";
import VideoThumbnailPlaceholder from "assets/images/thumbnail-video-generic.jpeg";
import ThumbnailImage1 from 'assets/images/statistics/1-2-prevalence-aod-removal-in-the-united-states-2000-to-2021.png';
import ThumbnailImage2 from 'assets/images/statistics/6-enter-out-of-home-care-age-at-removal.png';
import ThumbnailImage3 from 'assets/images/statistics/7-enter-out-of-home-care-age-at-removal-aod.png';
import ThumbnailImage4 from 'assets/images/statistics/1-2-prevalence-aod-removal-by-state-2021.png';
import ThumbnailImage5 from 'assets/images/statistics/4-enter-out-of-home-care-age-under-1.png';
import ThumbnailImage6 from 'assets/images/statistics/5-removal-age-under-1.png';
import ThumbnailImage7 from 'assets/images/statistics/8-treatment-admissions-by-gender.png';
import ThumbnailImage8 from 'assets/images/statistics/9-treatment-admissions-pregnant.png';
import ThumbnailImage9 from 'assets/images/statistics/3-child-removal-by-age-parental-aod-age-1-and-older.png';
import ThumbnailImage10 from 'assets/images/statistics/3-child-removal-by-age-parental-aod-under-age-1.png';

const isBrowser = typeof window !== "undefined"

const StatisticsSeriesPage=({location})=>{
        let thisLocationPathname = ''
        if (isBrowser) {
            thisLocationPathname = window.location.pathname;
        }
    const seo = {
      metaTitle: "Interactive Statistics Series",
      metaDescription: "This page contains data from 1) the Adoption and Foster Care Analysis and Reporting System (AFCARS) depicting national and state information related to parental alcohol or drug abuse a condition associated with removal and 2) the Treatment Episode Data Set Admissions (TEDS-A) depicting substance use disorder treatment admissions by gender and substance type."
    }
    const sortCards=data=>{
        data.sort((a, b) => {
            if (a.title < b.title) {
                return -1;
            }
        });
        return data;
    }
    return (
        <Layout location={location}>
            <SeoComponent title={seo.metaTitle} description={seo.metaDescription} keywords={seo.metaKeywords} />
            <div className="grid-container padding-bottom-0">
                <Breadcrumb pathname={thisLocationPathname} customCurrentPage={seo.metaTitle} />
                <h1 name="main-content" id="main-content">{seo.metaTitle}</h1>
                <div className="padding-bottom-5">
                    <div className="grid-row">
                        <div className="grid-col-12">
                            <p>This page contains data from 1) the Adoption and Foster Care Analysis and Reporting System (AFCARS) depicting national and state information related to parental alcohol or drug abuse a condition associated with removal and 2) the Treatment Episode Data Set Admissions (TEDS-A) depicting substance use disorder treatment admissions by gender and substance type.</p>
                            <p><strong>Viewing Note:</strong> Interactive charts and maps are only functional and best viewed on a desktop browser. Each statistics page includes a button to download the source data.</p>
                        </div>
                    </div>
                </div>
                <div className="margin-y-5">
                    <div className="usa-card-group videos-webinars-card">
                        {sortCards(interactiveStatisticsCharts).map((resource, idx)=>{
                            return (
                                <Card key={`interactive-statistics-chart-${idx+1}`}
                                grid="tablet-lg:grid-col-6 tablet:grid-col-12"
                                background="bg-white"
                                >
                                    <Card.Header>
                                        {resource.title && <h2 className="usa-card__heading">{resource.title.substring(0,120)}{resource.title.length > 120 && <span>...</span>}</h2> }
                                    </Card.Header>
                                    <Card.Media mediaClass="min-height-video-card-container">
                                        <div className="usa-card__img">
                                        {(() => {
                                        switch(resource.id) {
                                            case 1:
                                                return <img src={ThumbnailImage1} alt={`${resource.title} ${resource.subTitle}`} />
                                            case 2:
                                                return <img src={ThumbnailImage2} alt={resource.title} />
                                            case 3:
                                                return <img src={ThumbnailImage3} alt={resource.title} />
                                            case 4:
                                                return <img src={ThumbnailImage4} alt={`${resource.title} ${resource.subTitle}`} />
                                            case 5:
                                                return <img src={ThumbnailImage5} alt={resource.title} />
                                            case 6:
                                                return <img src={ThumbnailImage6} alt={resource.title} />
                                            case 7:
                                                return <img src={ThumbnailImage7} alt={resource.title} />
                                            case 8:
                                                return <img src={ThumbnailImage8} alt={resource.title} />
                                            case 9:
                                                return <img src={ThumbnailImage9} alt={`${resource.title} ${resource.subTitle}`} />
                                            case 10:
                                                return <img src={ThumbnailImage10} alt={`${resource.title} ${resource.subTitle}`} />
                                            default:
                                                return <img src={VideoThumbnailPlaceholder} alt="" />
                                            }
                                        })()}
                                        </div>
                                    </Card.Media>
                                    <Card.Body>
                                        <>
                                            <p>{resource.author.substring(0,44)}, {resource.year}</p> 
                                            <p>
                                                <span
                                                dangerouslySetInnerHTML={{
                                                __html: resource?.description.length > 136 ? resource?.description.substring(0,136) + "... " : resource?.description + " "
                                                }}
                                                className="card-text"
                                                />
                                                    (<Link to={`${resource.slug}`} aria-label={`Read More about ${resource.title} in a new page`}>Read More</Link>)
                                            </p>
                                        </>
                                    </Card.Body>
                                    <Card.Footer cardFooter="text-center">
                                        <Button color="primary-button" size="padding-x-5" labelText={`View Statistics for ${resource.title} in a new page`} link={resource.slug}>
                                            View Statistics
                                        </Button>      
                                    </Card.Footer>
                                </Card>
                            )
                        })}
                    </div>
                </div>
            </div>
        </Layout>
    )
}
export default StatisticsSeriesPage;